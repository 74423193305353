import React, { useEffect, useMemo } from "react";

import { Helmet } from "react-helmet-async";
import { useCallOffs } from "@hooks/useApiData";
import { getCallOffHref, paths } from "@src/paths";
import { Status, statusDetails } from "@src/types";
import SusTabBox from "@components/SusTabBox/SusTabBox";
import ModuleOverviewTable from "@components/ModuleOverviewTable/ModuleOverviewTable";
import { useDashboardState } from "@src/app.state";
import { Grid, IconButton } from "@mui/material";
import SusBox from "@components/SusBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";
import SusTypography from "@components/SusTypography";
import SusWidget from "@src/templates/SusWidget";

const List: React.FC = () => {
  const {
    setBreadcrumbs,
    viewConfigKey,
    viewSettings: { apiVersion, callOff: callOffViewSettings },
  } = useDashboardState();

  const {
    data: callOffs,
    totalCount: callOffTotalCount,
    loading,
  } = useCallOffs(apiVersion, { limit: 200 });

  useEffect(() => setBreadcrumbs([{ label: "Call-Offs", url: paths.callOffOverview }]), []);

  const getStatusColsOrDefault = (status?: Status) =>
    (status && callOffViewSettings.fields.byStatus?.[status]?.overviewList) ||
    callOffViewSettings.fields.default.overviewList;

  const statusConfig: { status?: Status; label: string }[] =
    callOffViewSettings.availableStatus.map((status) => ({
      label: statusDetails[status].label,
      status: status,
    }));
  statusConfig.unshift({
    label: "All",
  });

  const callOffOverviewPageData = useMemo(
    () =>
      statusConfig.map(({ label, status }) => {
        const rows = status
          ? callOffs.filter(
              (callOff) => callOff[callOffViewSettings.statusField || "status"] === status
            )
          : callOffs.filter(
              (callOff) => callOff[callOffViewSettings.statusField || "status"] !== "CANCELED"
            );
        return {
          tabTitle: label,
          tabContent: (
            <ModuleOverviewTable
              rows={rows}
              loading={loading}
              getHref={getCallOffHref}
              columns={getStatusColsOrDefault(status)}
              localStoragePrefix="CallOffTable"
              footer={`Showing ${rows.length} of ${callOffTotalCount} total call-offs`}
            />
          ),
        };
      }),
    [callOffs, callOffViewSettings, loading]
  );

  return (
    <>
      <Helmet>
        <title>Call-Offs | PEK Dashboard</title>
      </Helmet>
      {viewConfigKey === "EUBC" ? (
        <SusBox mb={3}>
          <Grid container spacing={3}>
            <Grid item md={12} lg={6} xl={5}>
              <SusWidget
                title="Information"
                sx={{
                  paddingTop: "0",
                }}
              >
                <SusBox sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton sx={{ mr: 1 }}>
                    <FontAwesomeIcon icon={faInfo} />
                  </IconButton>
                  <SusTypography variant="h6">
                    This page is currently under construction due to an internal system change. We
                    will inform you as soon as this service is available again.
                  </SusTypography>
                </SusBox>
              </SusWidget>
            </Grid>
          </Grid>
        </SusBox>
      ) : (
        <SusTabBox data={callOffOverviewPageData} />
      )}
    </>
  );
};

export default List;
