import React, { useEffect, useMemo } from "react";

import { Grid, IconButton } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useDashboardState } from "@src/app.state";
import useAlerts from "@hooks/useAlerts";
import useApiData from "@hooks/useApiData";
import { generateApiPath } from "@src/utils/helpers";
import { CallOff } from "@src/types";
import { deserializeCallOff } from "@src/utils/typing";
import CallOffDetailView from "@components/CallOffDetailView/CallOffDetailView";
import { apiPaths, paths } from "@src/paths";
import VesselFinder from "@components/VesselFinder/VesselFinder";
import SusBox from "@components/SusBox";
import SusWidget from "@src/templates/SusWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";
import SusTypography from "@components/SusTypography";

const Details: React.FC = () => {
  const navigate = useNavigate();
  const {
    setBreadcrumbs,
    viewConfigKey,
    viewSettings: { apiVersion },
  } = useDashboardState();
  const alerts = useAlerts();

  const { id } = useParams();
  const { data, initialLoading, error } = useApiData(
    generateApiPath(apiPaths.callOffDetail, { apiVersion, id })
  );

  const callOff: CallOff = useMemo(() => deserializeCallOff(data), [data]);
  const imo = useMemo(() => (data && "imo" in data ? data.imo : undefined), [data]);
  const vesselFinder = useMemo(
    () => <VesselFinder imo={imo} showTrack title={"VesselFinder"} loading={false} />,
    [imo]
  );

  // Show fetching errors
  useEffect(() => {
    if (!error) {
      return;
    }
    alerts.notifyError({
      title: "Not found",
      content:
        error?.response?.status === 404
          ? `Could not find call-off with id ${id}.`
          : "Sorry, something went wrong. Please try again later.",
      autoHideDuration: 5000,
    });
    navigate(paths.callOffOverview);
  }, [error]);

  // Set breadcrumbs
  useEffect(
    () =>
      setBreadcrumbs([
        { label: "Call-Offs", url: paths.callOffOverview },
        {
          label: callOff ? `${callOff.contractDistributorReference}-${callOff.deliveryId}` : "...",
        },
      ]),
    [callOff]
  );

  return viewConfigKey === "EUBC" ? (
    <SusBox mb={3}>
      <Grid container spacing={3}>
        <Grid item md={12} lg={6} xl={5}>
          <SusWidget
            title="Information"
            sx={{
              paddingTop: "0",
            }}
          >
            <SusBox sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{ mr: 1 }}>
                <FontAwesomeIcon icon={faInfo} />
              </IconButton>
              <SusTypography variant="h6">
                This page is currently under construction due to an internal system change. We will
                inform you as soon as this service is available again.
              </SusTypography>
            </SusBox>
          </SusWidget>
        </Grid>
      </Grid>
    </SusBox>
  ) : (
    <CallOffDetailView callOff={callOff} loading={initialLoading}>
      {imo && (
        <Grid container spacing={3} mt={0.5}>
          <Grid item xs={12} md={12} xl={12} sx={{ height: "400px" }}>
            {vesselFinder}
          </Grid>
        </Grid>
      )}
    </CallOffDetailView>
  );
};

export default Details;
