import { API_URI } from "./constants";
import { CallOff, Contract, ContractPosition } from "./types";
import { generatePath } from "react-router-dom";

// Frontend
const baseUrl = "/";

// API
export const apiBaseUrl = `${API_URI}`;
export type ApiVersion = "v1" | "v2";

export const paths = {
  home: `${baseUrl}contracts`,
  notFound: `${baseUrl}contracts`,
  /**
   * Auth
   */
  settings: `${baseUrl}preferences`,
  signIn: `${baseUrl}sign-in/`,
  confirmEmail: `${baseUrl}confirm-email/:key`,
  resetPassword: `${baseUrl}reset-password/`,
  resetPasswordEnterNew: `${baseUrl}reset-password/enter-new/`,
  /**
   * Modules
   */
  controlRoom: `${baseUrl}control-room`,
  contractOverview: `${baseUrl}contracts`,
  contractDetail: `${baseUrl}contracts/:id`,
  contractPositionDetail: `${baseUrl}contracts/:contractId/:id`,
  productOverview: `${baseUrl}products`,
  warehousingOverview: `${baseUrl}warehousing`,
  warehousingDetail: `${baseUrl}warehousing/:id`,
  callOffOverview: `${baseUrl}call-offs`,
  callOffDetail: `${baseUrl}call-offs/:id`,
  materialInTransitOverview: `${baseUrl}material-in-transit`,
  materialInTransitDetail: `${baseUrl}material-in-transit/:id`,
  upcomingVesselsOverview: `${baseUrl}upcoming-vessels`,
  upcomingVesselDetail: `${baseUrl}upcoming-vessels/:id`,
};

export const apiPaths = {
  /**
   * Auth
   */
  auth: {
    login: `auth/login/`,
    mfa: `auth/mfa/`,
    logout: `auth/logout/`,
    resetPassword: `auth/password/reset/`,
    setNewPassword: `auth/password/reset/confirm/`,
    changePassword: `auth/password/change/`,
    confirmEmail: `auth/confirm-email/:key/asd/`,
  },
  /**
   * User
   */
  currentUser: "current-user/",
  companyMemberships: "current-user/companies/",
  companyMembership: "current-user/companies/:companyId/",
  notificationSettings: `notification-settings/`,
  /**
   * Support
   */
  supportRequest: `support/contact/`,
  reorderRequest: `support/reorder/`,
  /**
   * Data
   */
  companies: "companies/",
  company: "companies/:id/",
  companyMembers: "companies/:id/members/",
  invitation: "companies/:companyId/invitations/:id/",
  resendInvitation: "companies/:companyId/invitations/:id/resend/",
  invitations: "companies/:companyId/invitations/",
  aggregate: "aggregate/:objectClass/:filterField/:filterValue/:sumField/:groupByField?/",
  /**
   * Versioned API
   */
  comment: `:apiVersion/comments/:id/`,
  comments: `:apiVersion/comments/`,
  notifications: `:apiVersion/notifications/`,
  readNotifications: `:apiVersion/notifications/?unread=False`,
  unreadNotifications: `:apiVersion/notifications/?unread=True`,
  notification: `:apiVersion/notifications/:id/`,
  user: ":apiVersion/users/:id/",
  superUsers: ":apiVersion/superusers/",
  contracts: ":apiVersion/contracts/",
  contractDetail: ":apiVersion/contracts/:id/",
  contractDetailPositions: ":apiVersion/contracts/:id/positions/",
  contractFileUpload: ":apiVersion/contracts/:id/file/",
  contractPositions: ":apiVersion/contract-positions/",
  contractPositionDetail: ":apiVersion/contract-positions/:id/",
  contractPositionFileUpload: ":apiVersion/contract-positions/:id/file/",
  callOffs: ":apiVersion/call-offs/",
  callOffDetail: ":apiVersion/call-offs/:id/",
  callOffDetailPositions: ":apiVersion/call-offs/:id/positions/",
  callOffFileUpload: ":apiVersion/call-offs/:id/file/",
  callOffPositions: ":apiVersion/call-off-positions/",
  callOffPositionDetail: ":apiVersion/call-off-positions/:id/",
  callOffPositionFileUpload: ":apiVersion/call-off-positions/:id/file/",
};

export const getContractPositionHref = (pos: ContractPosition) => {
  if (pos && pos?.id && pos?.contract) {
    return generatePath(paths.contractPositionDetail, {
      contractId: pos.contract.toString(),
      id: pos.id.toString(),
    });
  } else {
    return "#";
  }
};

export const getContractHref = (contract: Contract) => {
  if (contract && contract?.id) {
    return generatePath(paths.contractDetail, {
      id: contract.id.toString(),
    });
  } else {
    return "#";
  }
};

export const getCallOffHref = (co: CallOff) => {
  if (co && co?.id) {
    return generatePath(paths.callOffDetail, {
      id: co.id.toString(),
    });
  } else {
    return "#";
  }
};
